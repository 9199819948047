import request from "./request";

/**
 * 增值服务信息
 */
export const info = () => request.get("/vip/info");

/**
 * 缓存服务列表
 */
export const product = () => request.get("/vip/product");

/**
 * 新订单
 */
export const newOrder = (srv, code) =>
  request.post("/vip/order", { srv, code });

/**
 * 订单状态
 */
export const orderStatus = id => request.get("/vip/status", { params: { id } });
