<template>
  <Layout>
    <div class="app-vip">
      <div class="service-list">
        <!-- 群裂变 -->
        <div class="qun">
          <div class="service-info">
            <div class="info">
              <h2>
                <a-icon type="rise" /> 群裂变
                <a
                  target="_blank"
                  href="https://mp.weixin.qq.com/s?__biz=Mzk0MzIxMTU3Ng==&mid=2247483903&idx=1&sn=a12cd27802634b9afcd3e058dd298a12&chksm=c33614a8f4419dbef9dd543b8a322e87782f402cfd6f1c6ac927eae55d3391d20facff8bd99b&mpshare=1&scene=1&srcid=0719vCBDqSlexof7adz33zDd&sharer_sharetime=1626698992697&sharer_shareid=c06c71c3c15c0593f8066a019190f8ac&version=3.1.6.90174&platform=mac#rd"
                >
                  <a-icon type="question-circle-o" />
                </a>
              </h2>
              <div v-if="vip.qunStatus === 1">
                有效期：{{ vip.qunExpireAt | formatDate }}
              </div>
              <div class="status">
                <a-icon :type="serviceInfo.qun.icon" />
                {{ vip.qunStatusText }}
              </div>
            </div>
            <div class="desc">
              开通群裂变功能，可以实现分享N个群才能加入群的功能。适用于培训、课程、分销等领域。
            </div>

            <div v-if="vip.qunStatus === 1" class="enter">
              <a-button type="primary" size="large">
                <router-link :to="{ name: 'Group' }">
                  管理群裂变
                </router-link>
              </a-button>
            </div>

            <table class="ant-table fuwu-list">
              <thead class="ant-table-thead">
                <tr>
                  <th class="version">版本</th>
                  <th class="days">有效期</th>
                  <th class="price">价格</th>
                  <th class="action"></th>
                </tr>
              </thead>
              <tbody class="ant-table-tbody">
                <tr
                  :class="item.prime ? 'prime' : null"
                  v-for="item in productList('qun')"
                  :key="item.code"
                >
                  <td>{{ item.shortTitle || item.title }}</td>
                  <td>{{ item.days }}天</td>
                  <td>
                    <div class="new-price">
                      ￥{{ item.fee / 100 }}
                      <a-tag v-if="item.tag" color="#f50">{{ item.tag }}</a-tag>
                    </div>
                    <div v-if="item.originFee" class="origin-price">
                      原价:￥{{ item.originFee / 100 }}
                    </div>
                  </td>
                  <td>
                    <a-button
                      class="pay-btn"
                      :type="item.prime ? 'danger' : 'default'"
                      @click="newOrder('qun', item.code)"
                    >
                      {{ payBtnText(vip.qunStatus) }}
                    </a-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- 外链 -->
        <div class="qun">
          <div class="service-info">
            <div class="info">
              <h2><a-icon type="link" /> 外链 (企业店铺专用)</h2>
              <div v-if="vip.linkStatus === 1">
                有效期：{{ vip.linkExpireAt | formatDate }}
              </div>
              <div class="status">
                <a-icon :type="serviceInfo.link.icon" />
                {{ vip.linkStatusText }}
              </div>
            </div>
            <div class="desc">
              开通外链功能，生成的外部链接可以在短信、抖音、快手、小红书、知乎等任何渠道直达小商店。
            </div>

            <div v-if="vip.linkStatus === 1" class="enter">
              <a-button type="primary" size="large">
                <router-link :to="{ name: 'UrlLink' }">
                  管理外链
                </router-link>
              </a-button>
            </div>

            <table class="ant-table fuwu-list">
              <thead class="ant-table-thead">
                <tr>
                  <th class="version">版本</th>
                  <th class="days">有效期</th>
                  <th class="price">价格</th>
                  <th class="action"></th>
                </tr>
              </thead>
              <tbody class="ant-table-tbody">
                <tr
                  :class="item.prime ? 'prime' : null"
                  v-for="item in productList('link')"
                  :key="item.code"
                >
                  <td>{{ item.shortTitle || item.title }}</td>
                  <td>{{ item.days }}天</td>
                  <td>
                    <div class="new-price">
                      ￥{{ item.fee / 100 }}
                      <a-tag v-if="item.tag" color="#f50">{{ item.tag }}</a-tag>
                    </div>
                    <div v-if="item.originFee" class="origin-price">
                      原价:￥{{ item.originFee / 100 }}
                    </div>
                  </td>
                  <td>
                    <a-button
                      class="pay-btn"
                      :type="item.prime ? 'danger' : 'default'"
                      @click="newOrder('link', item.code)"
                    >
                      {{ payBtnText(vip.linkStatus) }}
                    </a-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="service-list">
        <!-- 搜一搜 -->
        <div class="qun">
          <div class="service-info">
            <div class="info">
              <h2><a-icon type="search" /> 搜一搜</h2>
              <div v-if="vip.searchStatus === 1">
                有效期：{{ vip.searchExpireAt | formatDate }}
              </div>
              <div class="status">
                <a-icon :type="serviceInfo.search.icon" />
                {{ vip.searchStatusText }}
              </div>
            </div>
            <div class="desc">
              提交小商店页面到微信搜一搜，提升外部自然流量。<br />
              提示：实际收录情况以微信搜索为准，请按规范使用，不保证一定可以收录。
            </div>

            <div v-if="vip.searchStatus === 1" class="enter">
              <a-button type="primary" size="large">
                <router-link :to="{ name: 'Search' }">
                  管理搜一搜
                </router-link>
              </a-button>
            </div>

            <table class="ant-table fuwu-list">
              <thead class="ant-table-thead">
                <tr>
                  <th class="version">版本</th>
                  <th class="days">有效期</th>
                  <th class="price">价格</th>
                  <th class="action"></th>
                </tr>
              </thead>
              <tbody class="ant-table-tbody">
                <tr
                  :class="item.prime ? 'prime' : null"
                  v-for="item in productList('search')"
                  :key="item.code"
                >
                  <td>{{ item.shortTitle || item.title }}</td>
                  <td>{{ item.days }}天</td>
                  <td>
                    <div class="new-price">
                      ￥{{ item.fee / 100 }}
                      <a-tag v-if="item.tag" color="#f50">{{ item.tag }}</a-tag>
                    </div>
                    <div v-if="item.originFee" class="origin-price">
                      原价:￥{{ item.originFee / 100 }}
                    </div>
                  </td>
                  <td>
                    <a-button
                      class="pay-btn"
                      :type="item.prime ? 'danger' : 'default'"
                      @click="newOrder('search', item.code)"
                    >
                      {{ payBtnText(vip.searchStatus) }}
                    </a-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- 剪贴板 -->
        <div class="qun">
          <div class="service-info">
            <div class="info">
              <h2><a-icon type="copy" /> 剪贴板</h2>
              <div v-if="vip.clipboardStatus === 1">
                有效期：{{ vip.clipboardExpireAt | formatDate }}
              </div>
              <div class="status">
                <a-icon :type="serviceInfo.clip.icon" />
                {{ vip.clipboardStatusText }}
              </div>
            </div>
            <div class="desc">
              开通剪贴板功能实现进入小商店自动复制内容到剪贴板，适用于淘宝客、分销等行业。
            </div>

            <div v-if="vip.clipboardStatus === 1" class="enter">
              <a-button type="primary" size="large">
                <router-link :to="{ name: 'Clipboard' }">
                  管理剪贴板
                </router-link>
              </a-button>
            </div>

            <table class="ant-table fuwu-list">
              <thead class="ant-table-thead">
                <tr>
                  <th class="version">版本</th>
                  <th class="days">有效期</th>
                  <th class="price">价格</th>
                  <th class="action"></th>
                </tr>
              </thead>
              <tbody class="ant-table-tbody">
                <tr
                  :class="item.prime ? 'prime' : null"
                  v-for="item in productList('clip')"
                  :key="item.code"
                >
                  <td>{{ item.shortTitle || item.title }}</td>
                  <td>{{ item.days }}天</td>
                  <td>
                    <div class="new-price">
                      ￥{{ item.fee / 100 }}
                      <a-tag v-if="item.tag" color="#f50">{{ item.tag }}</a-tag>
                    </div>
                    <div v-if="item.originFee" class="origin-price">
                      原价:￥{{ item.originFee / 100 }}
                    </div>
                  </td>
                  <td>
                    <a-button
                      class="pay-btn"
                      :type="item.prime ? 'danger' : 'default'"
                      @click="newOrder('clip', item.code)"
                    >
                      {{ payBtnText(vip.clipboardStatus) }}
                    </a-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="service-list">
        <!-- 无库存 -->
        <div class="qun">
          <div class="service-info">
            <div class="info">
              <h2><a-icon type="database" /> 自动下架</h2>
              <div v-if="vip.noStockStatus === 1">
                有效期：{{ vip.noStockExpireAt | formatDate }}
              </div>
              <div class="status">
                <a-icon :type="serviceInfo.stock.icon" />
                {{ vip.noStockStatusText }}
              </div>
            </div>
            <div class="desc">
              开通自动下架功能，系统每小时会自动扫描所有自营商品库存，当库存为0时自动下架商品。
            </div>

            <table class="ant-table fuwu-list">
              <thead class="ant-table-thead">
                <tr>
                  <th class="version">版本</th>
                  <th class="days">有效期</th>
                  <th class="price">价格</th>
                  <th class="action"></th>
                </tr>
              </thead>
              <tbody class="ant-table-tbody">
                <tr
                  :class="item.prime ? 'prime' : null"
                  v-for="item in productList('stock')"
                  :key="item.code"
                >
                  <td>{{ item.shortTitle || item.title }}</td>
                  <td>{{ item.days }}天</td>
                  <td>
                    <div class="new-price">
                      ￥{{ item.fee / 100 }}
                      <a-tag v-if="item.tag" color="#f50">{{ item.tag }}</a-tag>
                    </div>
                    <div v-if="item.originFee" class="origin-price">
                      原价:￥{{ item.originFee / 100 }}
                    </div>
                  </td>
                  <td>
                    <a-button
                      class="pay-btn"
                      :type="item.prime ? 'danger' : 'default'"
                      @click="newOrder('stock', item.code)"
                    >
                      {{ payBtnText(vip.noStockStatus) }}
                    </a-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- 访问加速 -->
        <div class="cache">
          <div class="service-info">
            <div class="info">
              <h2>
                <a-icon type="thunderbolt" /> 访问加速
                <a
                  target="_blank"
                  href="https://mp.weixin.qq.com/s?__biz=Mzk0MzIxMTU3Ng==&mid=2247483903&idx=2&sn=48b878f0a11df79f4acaddcd8fb7f600&chksm=c33614a8f4419dbe729f19cf14facd161e7ca9502f5804ab4d8e2c247e3a01bf013c85e9c520&mpshare=1&scene=1&srcid=0719vlXVnzmdFbHIARSL55gO&sharer_sharetime=1626699008484&sharer_shareid=c06c71c3c15c0593f8066a019190f8ac&version=3.1.6.90174&platform=mac#rd"
                >
                  <a-icon type="question-circle-o" />
                </a>
              </h2>
              <div v-if="vip.cacheStatus === 1">
                刷新频率：{{ vip.minute }}分钟，有效期：{{
                  vip.expireAt | formatDate
                }}
              </div>
              <div class="status">
                <a-icon :type="serviceInfo.cache.icon" />
                {{ vip.cacheStatusText }}
              </div>
            </div>
            <div class="desc">
              访问加速功能是装修神器推出的一项增值服务，针对店铺加载速度进行优化，可以实现页面秒开。<br />
              适合拥有大量商品、注重用户体验的卖家。<br />
              原理：系统通过应用大量缓存提高访问速度，上架新品时会有延迟显示现象，刷新频率越小延迟越低。
            </div>

            <table class="ant-table fuwu-list">
              <thead class="ant-table-thead">
                <tr>
                  <th class="version">版本</th>
                  <th class="days">有效期</th>
                  <th class="freq">刷新频率</th>
                  <th class="price">价格</th>
                  <th class="action"></th>
                </tr>
              </thead>
              <tbody class="ant-table-tbody">
                <tr
                  :class="item.prime ? 'prime' : null"
                  v-for="item in productList('cache')"
                  :key="item.code"
                >
                  <td>{{ item.shortTitle || item.title }}</td>
                  <td>{{ item.days }}天</td>
                  <td>{{ item.minute }}分钟</td>
                  <td>
                    <div class="new-price">
                      ￥{{ item.fee / 100 }}
                      <a-tag v-if="item.tag" color="#f50">{{ item.tag }}</a-tag>
                    </div>
                    <div v-if="item.originFee" class="origin-price">
                      ￥{{ item.originFee / 100 }}
                    </div>
                  </td>
                  <td>
                    <a-button
                      class="pay-btn"
                      :type="item.prime ? 'danger' : 'default'"
                      @click="newOrder('cache', item.code)"
                    >
                      {{ payBtnText(vip.cacheStatus) }}
                    </a-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="service-list">
        <!-- 分析 -->
        <div class="qun">
          <div class="service-info">
            <div class="info">
              <h2><a-icon type="pie-chart" /> 数据分析（限时免费）</h2>
            </div>
            <div class="desc">
              了解小商店流量数据详情，了解用户访问路径和画像，优化转化路径，提升购买转化率、复购率。
            </div>

            <div class="enter">
              <a-button type="primary" size="large">
                <a
                  target="_blank"
                  ref="nofollow noopener noreferrer"
                  href="https://wedata.weixin.qq.com/"
                >
                  前往小商店分析
                </a>
              </a-button>

              <img class="data-tool-img" src="@/assets/data-tool.png" />
              <div>手机数据助手</div>
            </div>
          </div>
        </div>

        <!-- 代装修 -->
        <div class="qun">
          <div class="service-info">
            <div class="sep">
              <div>
                <div class="info">
                  <h2><a-icon type="skin" /> 店铺代装修（付费）</h2>
                </div>
                <div class="desc">
                  装修神器与众多专业设计师合作，面向小商店卖家提供店铺装修、美化设计服务。
                </div>
              </div>

              <div>
                <div class="info">
                  <h2><a-icon type="setting" /> 收银系统二次开发</h2>
                </div>
                <div class="desc">
                  装修神器开发团队拥有超过10年的电商系统和收银系统开发经验。提供系统定制化开发服务，支持的收银系统品牌有二维火、哗啦啦、美味不用等、银豹、客如云、思迅等。
                </div>
              </div>
            </div>

            <div class="enter">
              <img class="kf-img" src="@/assets/kf.png" />
              <div>扫码加客服沟通需求</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 付款二维码 -->
    <a-modal
      :destroyOnClose="true"
      :footer="null"
      :visible="dialogVisible"
      @cancel="closeDialog"
      :bodyStyle="{
        'text-align': 'center'
      }"
    >
      <div class="title">
        微信扫码付款开通
      </div>
      <vue-qrcode :width="200" :value="qrcode" />
      <div>
        如果付款后长时间没有开通，请
        <router-link to="/help">联系客服</router-link>
      </div>
    </a-modal>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

import VueQrcode from "vue-qrcode";

const VipSvc = require("@/service/vip");

const STATUS_ICON = {
  0: "hourglass",
  1: "caret-right",
  "-1": "pause"
};

const STATUS_FIELDS = {
  cache: "cacheStatus",
  qun: "qunStatus",
  clip: "clipboardStatus",
  stock: "noStockStatus",
  link: "linkStatus",
  search: "searchStatus"
};

export default {
  name: "Vip",

  data() {
    return {
      vip: {},
      product: {},

      dialogVisible: false,
      qrcode: ""
    };
  },

  computed: {
    serviceInfo() {
      const {
        cacheStatus,
        qunStatus,
        clipboardStatus,
        noStockStatus,
        linkStatus,
        searchStatus
      } = this.vip;

      return {
        cache: { icon: STATUS_ICON[cacheStatus] },
        qun: {
          icon: STATUS_ICON[qunStatus]
        },
        clip: {
          icon: STATUS_ICON[clipboardStatus]
        },
        stock: {
          icon: STATUS_ICON[noStockStatus]
        },
        link: {
          icon: STATUS_ICON[linkStatus]
        },
        search: {
          icon: STATUS_ICON[searchStatus]
        }
      };
    }
  },

  methods: {
    payBtnText(status) {
      return status === 0 ? "开通服务" : "续费";
    },

    productList(service) {
      let productList = this.product[service];
      if(!productList) return [];

      if (this.vip[STATUS_FIELDS[service]] !== 0) {
        productList = productList.filter(x => !x.isTry);
      }

      return productList;
    },

    closeDialog() {
      this.dialogVisible = false;

      if (this.timer) {
        clearTimeout(this.timer);
      }
    },

    async newOrder(service, code) {
      try {
        const { code_url, id } = await VipSvc.newOrder(service, code);

        // 免费试用时直接成功
        if (code_url === "success") {
          await this.loadData();
          return;
        }

        this.qrcode = code_url;
        this.orderId = id;

        this.dialogVisible = true;

        this.timer = setTimeout(this.checkOrderStatus, 5000);
      } catch (err) {
        console.error(err);
        this.$message.error("生成订单失败：" + err.message);
      }
    },

    async checkOrderStatus() {
      try {
        const { status } = await VipSvc.orderStatus(this.orderId);
        if (status === 1) {
          await this.loadData();

          this.dialogVisible = false;

          this.$message.info("感谢购买增值服务");
        } else {
          this.timer = setTimeout(this.checkOrderStatus, 5000);
        }
      } catch (err) {
        console.error(err);

        this.timer = setTimeout(this.checkOrderStatus, 5000);
      }
    },

    async loadData() {
      this.vip = await VipSvc.info();
      this.product = await VipSvc.product();
    }
  },

  async mounted() {
    await this.loadData();
  },

  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },

  components: {
    Layout,
    VueQrcode
  }
};
</script>

<style lang="scss">
.app-vip {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .service-list {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    & > div {
      width: 50%;
      margin-right: 20px;

      &:nth-child(even) {
        margin-right: 0;
      }
    }
  }

  .service-info {
    border: 1px solid #ebedf0;
    padding: 20px;
    border-radius: 10px;
    background: #fff;

    .sep {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & > div {
        flex: 1;
      }
    }

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        margin: 0;
      }
      .status {
        font-size: 20px;
      }
    }

    .desc {
      margin: 10px 0;
      font-size: 12px;
      color: #999;
      background: #fdfdfd;
      padding: 10px;
      border-radius: 5px;
    }

    .enter {
      margin-bottom: 20px;
      text-align: center;

      .ant-btn {
        width: 100%;
      }

      .data-tool-img {
        width: 100%;
        max-width: 540px;
      }
      .kf-img {
        width: 200px;
      }
    }
  }

  .fuwu-list {
    width: 100%;

    tr.prime {
      background: linear-gradient(-45deg, #ffffff, #ffeb3b, #ffffff73, #ffeb3b);
    }

    .version {
    }
    .days {
      width: 100px;
    }
    .freq {
      width: 100px;
    }
    .price {
      width: 150px;
    }
    .action {
      width: 100px;
    }

    .new-price {
      color: #c00;
    }
    .origin-price {
      text-decoration: line-through;
    }

    .pay-btn {
    }
  }
}
</style>
